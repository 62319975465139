export const initialState = {
  formData: {
    email: "",
    name: "",
    message: "",
  },
  joiErrors: {},
  scrollPosition: { prev: 0, now: 0 },
  theme: "dark",
};
