import React from "react";

function Footer() {
  return (
    <footer>
      <div>&copy; Ardalan Al-Jaf 2022</div>
    </footer>
  );
}

export default Footer;
